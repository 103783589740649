import {graphql} from 'gatsby';
import React, {useContext, useEffect} from 'react';
import Seo from '../components/Seo/Seo';
import BoxGridWrapper from '../components/Wrapper/BoxGridWrapper';
import PostCard from '../components/PostCard/PostCard';
import SubHeader from '../components/Header/SubHeader';
import PageButtons from '../components/Pagination/PageButtons';
import ThemeContext from '../store/theme-context';
import SectionHeading from '../components/_baseComponents/SectionHeading/SectionHeading';
import BoxWrapper from '../components/Wrapper/BoxWrapper';
import {StaticImage} from 'gatsby-plugin-image';

const TagTemplate = ({
  data: {allWpPost, wpTag},
  pageContext: {numPages, currentPage},
}) => {
  // const themeContext = useContext(ThemeContext);

  // useEffect(() => {
  //   themeContext.setNavTransparent(true);
  //   return () => {
  //     themeContext.setNavTransparent(false);
  //   };
  // }, [themeContext]);

  //Take 2 post in array
  let allWpPostFirst = allWpPost.nodes.slice(0, 2);
  // Take all post at index 2 to n
  let allWpPostSecond = allWpPost.nodes.slice(2);

  // Take the Categories have a post
  let getCategoryItems = (nodes) => {
    let cateItems = nodes.map((node) => {
      if (node.wpChildren.nodes.length > 0) {
        return node;
      }
    });
    return cateItems;
  };

  return (
    <div className="flex flex-col gap-10 bg-[#F7F8FA] pt-[70px] lg:pt-20">
      {/* Heading Section */}
      <BoxWrapper className="relative top-[1rem] mb-4 flex flex-col items-center gap-5 rounded-xl bg-[#4767ec1a] pt-8 md:flex-row md:py-0 md:pt-0">
        <div className="flex w-full flex-col items-center justify-center gap-5 px-2 text-center md:w-[40%] md:items-start md:p-0 md:pl-8 md:text-left lg:pl-32">
          <h1 className="text-3xl font-bold md:text-4xl lg:text-6xl">Tag</h1>
          <p>Những bài post chuyên sâu hơn về một vấn đề</p>
        </div>

        <StaticImage
          src="../assets/blog/man-working-on-laptop.png"
          alt="Heading section image"
          className="w-full md:w-[60%]"
          objectFit="cover"
        />
      </BoxWrapper>

      <SectionHeading
        content={wpTag.name}
        className="flex w-full flex-col items-center gap-3 "
      />
      {/* blog post first block */}
      {allWpPostFirst.length <= 1 ? (
        <BoxWrapper className="w-[50%] justify-center">
          {allWpPostFirst.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </BoxWrapper>
      ) : (
        <BoxWrapper className="gap-5 lg:w-full">
          {allWpPostFirst.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </BoxWrapper>
      )}

      {/* blog post second block */}
      <BoxWrapper>
        <BoxGridWrapper className="gap-5">
          {allWpPostSecond.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </BoxGridWrapper>
      </BoxWrapper>

      {/* For Pagination */}
      <BoxWrapper className="items-center justify-center">
        {numPages > 1 && (
          <PageButtons
            prefixPathName={wpTag.uri}
            currentPage={currentPage}
            numPages={numPages}
          />
        )}
      </BoxWrapper>
    </div>
  );
};

export default TagTemplate;

export const Head = ({data: {wpTag}}) => {
  return (
    <Seo
      yoastSeo={wpTag.seo}
      title={wpTag.title}
      uri={wpTag.uri}
      description={wpTag.content && wpTag.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query ($tagId: String!, $skip: Int!, $limit: Int!) {
    wpTag(id: {eq: $tagId}) {
      id
      uri
      name
      description
      seo {
        fullHead
        canonical
        breadcrumbs {
          text
          url
        }
      }
    }
    allWpPost(
      filter: {tags: {nodes: {elemMatch: {id: {eq: $tagId}}}}}
      sort: {order: DESC, fields: date}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        excerpt
        uri
        slug
        date(formatString: "DD.MM.YYYY")
        categories {
          nodes {
            id
            name
            uri
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 200)
              }
            }
          }
        }
      }
    }
  }
`;
