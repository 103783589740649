import React, {memo} from 'react';
import Breadcrumb from '../BreadCrumb/BreadCrumb';
/**
 *
 * @param {Object} props - Component's props
 * @param {string} props.pageName - Name of page
 * @param {Array} props.breadcrumbs - For Breadcrumb container
 * @param {Object} props.breadcrumbsColorConfig Config color of breadcrumb
 * @param {string} props.breadcrumbsColorConfig.parentColor Color of parent pages
 * @param {string} props.breadcrumbsColorConfig.separatorColor Color of separator
 * @param {string} props.breadcrumbsColorConfig.currentColor Color of current
 * @returns
 */
const SubHeader = ({pageName, breadcrumbs, breadcrumbsColorConfig}) => {
  return (
    <div className="-mt-20 bg-gradient-to-r from-kdigi-background-1 to-kdigi-background-2 py-[8rem] text-center lg:-mt-24">
      <h1 className="w-full text-3xl font-bold text-white">{pageName}</h1>
      <Breadcrumb
        breadcrumbs={breadcrumbs}
        colorConfig={breadcrumbsColorConfig}
      />
    </div>
  );
};

export default memo(SubHeader);
