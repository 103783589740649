import React, {Fragment, memo} from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import {TbChevronRight} from '@react-icons/all-files/tb/TbChevronRight';

const defaultColorConfig = {
  parentColor: 'text-link',
  separatorColor: '',
  currentColor: '',
};
/**
 * Create breadcrumbs
 * @param {Object} props Component props
 * @param {Array} props.breadcrumbs Array of breadcrumbs
 * @param {string} props.breadcrumbs.text Name of breadcrumb
 * @param {string} props.breadcrumbs.url Url of breadcrumb
 * @param {Object} props.colorConfig Config color of breadcrumb
 * @param {string} props.colorConfig.parentColor Color of parent pages
 * @param {string} props.colorConfig.separatorColor Color of separator
 * @param {string} props.colorConfig.currentColor Color of current page
 * @returns
 */
const Breadcrumb = ({breadcrumbs, colorConfig = {}}) => {
  // Merge config with default config to avoid undefined properties
  colorConfig = {...defaultColorConfig, ...colorConfig};

  return (
    <div className="">
      {breadcrumbs && (
        <>
          {breadcrumbs.slice(0, -1).map((item) => (
            <Fragment key={item.url}>
              <UniversalLink
                to={item.url}
                className={`${colorConfig.parentColor} group mr-1 text-sm `}
              >
                <span
                  className={`${TailwindTransitions.textDecoration.underline.leftToRight.color_primary['40%']}`}
                  dangerouslySetInnerHTML={{__html: item.text}}
                />
              </UniversalLink>
              <TbChevronRight
                className={`${colorConfig.separatorColor} mr-1 inline-block stroke-gray-500 text-sm font-bold`}
              />
            </Fragment>
          ))}
          <span
            className={`${colorConfig.currentColor} text-sm`}
            dangerouslySetInnerHTML={{__html: breadcrumbs.at(-1).text}}
          />
        </>
      )}
    </div>
  );
};

export default memo(Breadcrumb);
