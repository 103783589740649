import React from 'react';

/**
 *
 * @param {Object} props
 * @param {string} props.content
 * @param {string} props.subContent
 * @param {string} props.className
 * @param {string} props.contentClassName
 * @param {string} props.subContentClassName
 * @returns
 */
const SectionHeading = ({
  content,
  subContent,
  className = 'max-w-lg items-center',
  contentClassName = 'text-2xl md:text-3xl lg:text-4xl',
  subContentClassName = 'text-center',
  lineBlockClassName = 'block',
}) => {
  return (
    <div className={`flex flex-col gap-3  ${className}`}>
      <h2 className={`font-bolder ${contentClassName}`}>{content}</h2>
      <div
        className={`h-1 w-16 rounded-tr rounded-tl bg-primary lg:h-2 ${lineBlockClassName}`}
      ></div>
      <p className={`font-medium text-gray-500 ${subContentClassName}`}>
        {subContent}
      </p>
    </div>
  );
};

export default SectionHeading;
